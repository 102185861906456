import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MdArrowBackIos } from 'react-icons/md';
import { Document, Page, pdfjs } from 'react-pdf';

import { Box, Spinner } from '@chakra-ui/react';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFView = () => {

  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const pdfLink = location.state.pdfLink;
  const [numPages, setNumPages] = useState(null);

  console.log(pdfLink);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);

  }

  const onPageLoadSuccess = () => {

    setIsLoading(false);
  }

  //sequential page loading
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLoaded, setPageLoaded] = useState(1);

  const handlePageLoad = (page) => {
    setPageLoaded(pageLoaded + 1);
  }


  const handleBack = () => {
    window.history.back();
  }



  useEffect(() => {
    window.scrollTo(0, 0)
  }
    , [])

  return (
    <div className="flex flex-col justify-items-center w-full h-auto bg-gray-50 overflow-hidden">
      <div className='top-2 bg-white shadow-md w-full h-20 flex pt-4'>
        <div className='flex-start font-bold ml-16 pt-2'>
          <MdArrowBackIos size={15} onClick={() => handleBack()} className='bold-icon font-bold cursor-pointer' />
        </div>
        <div className='flex-grow text-center'>
          <h5 className='text-xl font-lato'>পবিত্র কুরআন </h5>
        </div>

      </div>


      {isLoading && (
        <Box
          style={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
          }}
        >
          <Spinner
            thickness="4px"
            speed="0.85s"
            emptyColor="gray.200"
            color="teal.500"
            size="xl"
          className={isLoading ? '' : 'hidden'}
          />
        </Box>
      )}

      <div position="relative">
        <Document file={pdfLink} onLoadSuccess={onDocumentLoadSuccess} loading=''>

          {/* for (let i = currentPage; i <= numPages; i++) {
      pages.push(
        <div key={i} style={{ marginBottom: '20px' }}>
          <Viewer file="/path/to/your/pdf/document.pdf" pageNumber={i} />
        </div>
      );
    } */}

          {Array.from(new Array(numPages), (el, index) => (

            <Page
              //load page 1 initially

              onLoadSuccess={onPageLoadSuccess}
              pageNumber={index + 1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              width={window.innerWidth}
              height={window.innerHeight}
            />

          ))
          }


        </Document>
      </div>



    </div>


  );
};

export default PDFView;